import request from '@/plugins/axios'

// 获取方案列表
export function getPlanList(params) {
  return request({
    url: '/clue/plans',
    method: 'get',
    params
  })
}

// 新增方案
export function addPlan(data) {
  return request({
    url: '/clue/plans',
    method: 'post',
    data
    // headers: {
    //   'Content-Type': 'application/x-www-form-urlencoded'
    // }
  })
}

// 根据方案id获取指定方案
export function getPlanById(id) {
  return request({
    url: `/clue/plans/${id}`,
    method: 'get'
  })
}

// 根据方案id获取方案的结果
export function getPlanResult(id, params) {
  return request({
    url: `/clue/plans/${id}/results`,
    method: 'get',
    params
  })
}
// 删除方案
export function deletePlan(id) {
  return request({
    url: `/clue/plans/${id}`,
    method: 'delete'
  })
}
// 修改方案
export function updateApi(id, data) {
  return request({
    url: `/clue/plans/${id}`,
    method: 'put',
    data
  })
}
