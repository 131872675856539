<template>
  <div class="app-container">
    <div class="left_box" v-loading="planLoading">
      <div class="plan_title">我的方案</div>
      <div
        class="plan-list"
        v-infinite-scroll="scrollLoad"
        :infinite-scroll-disabled="planDisabled"
        :infinite-scroll-distance="10"
      >
        <el-collapse v-model="activeNames" accordion>
          <el-collapse-item v-for="(item, i) in planList" :key="i" :name="i">
            <template slot="title">
              <div
                class="programme-title"
                @click="changePlan(item)"
                :class="selectData.id == item.id ? 'select-data' : ''"
              >
                <el-badge
                  v-if="item.not_read_count !== 0"
                  :value="item.not_read_count"
                  class="item"
                >
                  <span>
                    {{ item.name }}
                  </span>
                </el-badge>
                <span v-else>
                  {{ item.name }}
                </span>
                <span class="time">
                  {{ item.create_time }}
                </span>
              </div>
            </template>
            <div class="collapse-content">
              <ul>
                <li
                  @click="changePlan(ele)"
                  v-for="(ele, j) in dataChange(item.include, item.id)"
                  :key="j"
                  :class="selectData.id == ele.id ? 'select-data-2' : ''"
                >
                  {{ ele.name }}
                </li>
              </ul>
            </div>
          </el-collapse-item>
        </el-collapse>
        <p v-if="planList.length > 0 && !planNomore" class="loading-text">
          加载中...
        </p>
        <p v-if="planList.length > 0 && planNomore" class="loading-text">
          没有更多了~
        </p>
        <div
          class="content-no"
          style="width: 100%;text-align: center;"
          v-if="planList.length == 0"
        >
          暂无数据,请添加方案后查看~
        </div>
      </div>

      <!-- <span v-if="this.planList.length === 0">请添加方案后查看</span> -->
    </div>
    <div class="content-box" v-loading="listLoading">
      <div class="content-box-child" id="clue-result-page">
        <div
          v-infinite-scroll="scrollMessage"
          infinite-scroll-disabled="disabled"
        >
          <SingleMessage
            v-for="(item, i) in list"
            :key="i"
            :singleInfo="item"
            :detailType="true"
            @updateFun="updateFun"
            tagType="msg"
          />
        </div>
        <p class="loading-text" v-if="list.length > 0 && !noMore">加载中...</p>
        <p class="loading-text" v-if="list.length > 0 && noMore">没有更多了</p>
        <div class="content-no" v-if="list.length == 0">
          暂无数据~
        </div>
      </div>
    </div>
    <div class="right_box">
      <searchRight ref="searchRightRef" @submitFun="submitFun" />
    </div>
  </div>
</template>

<script>
import { requestMixin } from '@/mixins/search'
import { cacheMixin } from '@/mixins/mixin'
import { scrollTopFun } from '@/plugins/fun'
import { getPlanList } from '@/api/clue'
import { messagesApi } from '@/api/whatsApp'

import SingleMessage from '@/views/search/componentens/SingleMessage.vue'

export default {
  components: { SingleMessage },
  name: 'clueResult',
  mixins: [cacheMixin, requestMixin],
  data() {
    return {
      activeNames: '',
      noMore: false,
      list: [],
      listLoading: false,
      count: 0,
      page_index: 1,
      page_size: 20,
      disabled: false,
      //方案
      planIndex: 1,
      planSize: 30,
      planNomore: false,
      planList: [],
      planId: null,
      planLoading: false,
      planDisabled: false,
      //
      selectData: {},
      listScrollTop: 0,
      queryForm: {}
    }
  },
  computed: {},
  created() {
    // this.fetchData()
    // this.getPlanListInfo()
    // if (this.planList.length === 0) {
    //   this.noMore = true
    // } else {
    //   // this.noMore = false
    //   // this.planList[0].not_read_count = 0
    //   // this.fetchData()
    // }
    this.planDisabled = true

    this.disabled = true
    this.$nextTick(() => {
      this.scrollMessage()
    })
  },
  activated() {
    scrollTopFun('clue-result-page', 'set', this.listScrollTop)
    // 刷新方案
    this.planNomore = false
    this.planList = []
    this.planIndex = 1
    this.getPlanListInfo()
  },
  deactivated() {
    this.listScrollTop = scrollTopFun('clue-result-page', 'get')
  },
  methods: {
    changePlan(item) {
      this.selectData = Object.assign({}, item)

      this.page_index = 1
      this.noMore = false
      this.list = []
      this.disabled = true

      this.scrollMessage()
    },
    fetchData() {
      const obj = {
        ordering: '-send_time',
        page_index: this.page_index,
        page_size: this.page_size
      }
      let search = ''
      if (this.selectData.id && this.selectData.include) {
        search = this.selectData.include.split(',')
        obj.search = search
      } else {
        obj.search = this.selectData.name
      }

      this.listLoading = true
      this.disabled = true
      messagesApi(obj).then(res => {
        if (res.items.length < res.page_size) {
          this.noMore = true
        } else {
          this.page_index = this.page_index + 1
          this.disabled = false
        }
        this.list = [...this.list, ...res.items]

        this.count = res.count
        this.listLoading = false
      })
    },
    scrollMessage() {
      this.requestFun()
    },
    getPlanListInfo() {
      this.planDisabled = true
      this.planLoading = true
      getPlanList({
        page_index: this.planIndex,
        page_size: this.planSize
      }).then(res => {
        if (res.items.length < this.planSize) {
          this.planNomore = true
        } else {
          this.planIndex = this.planIndex + 1
          this.planDisabled = false
        }
        this.planList = [...this.planList, ...res.items]
        this.planLoading = false
      })
    },
    scrollLoad() {
      this.requestFun()
    },
    dataChange(str, id) {
      if (!str) return []
      let arr = str.split(',')
      let new_arr = arr.map((item, i) => {
        return {
          name: item,
          id: `${id}-${i}`
        }
      })
      return new_arr || []
    },
    requestFun() {
      const obj = {
        ordering: '-send_time',
        page_index: this.page_index,
        page_size: this.page_size
      }
      let search = []
      if (this.selectData.id && this.selectData.include) {
        search = this.selectData.include.split(',')
        obj.search = search
      } else {
        obj.search = this.selectData.name
      }
      //
      let rightObj = this.$refs.searchRightRef.queryForm

      if (rightObj.search) {
        this.$refs.searchRightRef.queryForm.search = null
      }
      let query = {
        ...rightObj,
        ...obj
      }

      this.queryForm = { ...this.queryForm, ...query }
      if (this.queryForm.radio == 1) {
        this.getMsg1Scoll()
      } else if (this.queryForm.radio == 2) {
        this.getMsg2Scoll()
      } else if (this.queryForm.radio == 3) {
        this.getMsg3Scoll()
      }
    },
    submitFun() {
      this.selectData = {}
      this.page_index = 1
      this.list = []
      this.listScrollTop = 0
      this.noMore = false
      const obj = {
        ordering: '-send_time',
        page_index: this.page_index,
        page_size: this.page_size
      }
      let search = ''
      if (this.selectData.id && this.selectData.include) {
        search = this.selectData.include.split(',')
        obj.search = search
      } else {
        obj.search = this.selectData.name
      }
      //
      let rightObj = this.$refs.searchRightRef.queryForm

      let query = {
        ...obj,
        ...rightObj
      }
      if (!query.search) {
        delete query.search
      }
      this.queryForm = { ...query }
      if (this.queryForm.radio == 1) {
        this.getMsg1Scoll()
      } else if (this.queryForm.radio == 2) {
        this.getMsg2Scoll()
      } else if (this.queryForm.radio == 3) {
        this.getMsg3Scoll()
      }
    },
    updateFun() {
      this.page_index = 1
      this.noMore = false
      this.list = []
      this.disabled = true
      this.requestFun()
    }
  },
  watch: {}
}
</script>
<style lang="scss" scoped>
.app-container {
  display: flex;
  justify-content: space-between;

  .left_box {
    width: 300px;
    height: calc(100vh - 110px);
    padding: 10px;
    box-sizing: border-box;

    border: 1px solid #e5e5e5;
    overflow: hidden;
    box-shadow: 0 1px 0px 0 #d8d9d9;
    border-radius: 5px;
    .plan_title {
      text-align: center;
      font-size: 18px;
      color: #0078f2;
    }
    .plan-list {
      width: 100%;
      height: calc(100% - 40px);
      overflow: auto;
      position: relative;
    }

    .plan {
      height: 46px;
      line-height: 46px;
      border-bottom: 1px solid #e2e3e3;
      cursor: pointer;
    }
    ::v-deep sup.el-badge__content.is-fixed {
      top: 18px;
      right: -2px;
    }
    .programme-title {
      display: flex;
      .time {
        color: #949698;
        margin-left: 20px;
      }
    }
    .collapse-content {
      padding: 0 20px;
      box-sizing: border-box;
      ul {
        margin: 0;
        padding: 0;
        background: #cbccce;
        li {
          overflow: hidden;
          user-select: none;
          list-style: none;
          padding: 5px 10px;
          box-sizing: border-box;
          cursor: pointer;
          border-bottom: 1px solid #b8b5b5;
          // background: #cbccce;
          // border: 1px solid #e0dddd;
          // border-bottom: none;
          &:hover {
            color: #0078f2;
          }
        }
        :nth-last-child(1) {
          border-bottom: 1px solid #e0dddd;
        }
        .select-data-2 {
          background: #90c4f8;
          color: #ffffff;
          border: none;
        }
      }
    }
  }
  .content-box {
    width: calc(100% - 620px);
    height: calc(100vh - 110px);

    border: 1px solid #e5e5e5;
    box-shadow: 0 1px 0px 0 #d8d9d9;
    border-radius: 5px;
    padding: 10px;
  }
  .content-box-child {
    width: 100%;
    height: 100%;

    overflow: auto;
    position: relative;
    .nomore_box {
      text-align: center;
      font-size: 14px;
      color: #949698;
    }
  }
  .right_box {
    width: 300px;
    height: calc(100vh - 110px);
  }
  .loading-text {
    font-size: 12px;
    color: #949698;
    text-align: center;
  }
  .content-no {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 14px;
    color: #949698;
  }
  .select-data {
    color: #0078f2;
    font-weight: 600;
  }
}
</style>
